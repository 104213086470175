<template>
  <div>
    <b-overlay
      :show="selected_requirement_status !== apiStatus.LOADED"
      class="mt-1"
      spinner-variant="primary"
      rounded="sm"
      spinner-type="grow"
    >
      <b-tabs id="menu" class="" content-class="mt-3">
        <b-tab active title="Requirement">
          <h3>
            <span class="select-all mr-50">{{ selected_requirement.properties.display_id }}</span>
            <span class="font-small-3">[ {{ selected_requirement.properties.priority }} ]</span>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              style="float: right"
              size="sm"
            >
              <template #button-content>
                <feather-icon icon="SettingsIcon" class="mr-50" />
                Actions
              </template>
              <b-dropdown-item @click="$bvModal.show('edit-requirement-modal')">
                Edit Requirement
              </b-dropdown-item>
              <b-dropdown-item
                v-if="selected_requirement.properties.deleted"
                @click="$store.dispatch('requirements/restoreRequirement', selected_requirement.properties.id)"
              >
                <span class="text-success">Restore</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="$bvModal.show('move-requirement-modal')">
                Move
              </b-dropdown-item>
              <b-dropdown-item @click="$bvModal.show('copy-requirement-modal')">
                Clone
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="$bvModal.show('backward-trace-requirement-modal')">
                Trace
              </b-dropdown-item>
              <b-dropdown-item @click="$bvModal.show('forward-trace-requirement-modal')">
                Forward Trace
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="$bvModal.show('parse-requirement-modal2')">
                Parse
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="$bvModal.show('impact-requirement-modal')">
                Impact Analysis
              </b-dropdown-item>
              <b-dropdown-item @click="openHistory()">
                History
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item v-if="!selected_requirement.properties.deleted" @click="$bvModal.show('delete-requirement-modal')">
                <span class="text-danger">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </h3>
          <hr>
          <vue-perfect-scrollbar
            v-if="!isEditingRequirementObjectText"
            :settings="perfectScrollbarSettings"
            class="scroll-area-idea-entities"
            style="max-height: 15rem"
          >
            <p
              @click="isEditingRequirementObjectText = true"
              v-sanitized-html="selected_requirement.properties.object_text"
            />
          </vue-perfect-scrollbar>
          <div v-else>
            <tip-tap-editor
              v-model="requirementObjectText"
              placeholder="Edit Requirement text..."
              class="mb-50 clearfix"
              min-height="10"
              max-height="10"
              @keydown.ctrl.enter.native="handleEnter"
            />
          </div>
          <b-row>
            <b-col>
              <b-form-group>
                <LabelWithHoverIcon
                  label-string="Requirement Priority"
                  popover-text="Decide the priority of your Requirement here."
                />
                <b-form-select
                  v-model="priority"
                  :options="priority_options"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="success"
                class="mr-50"
                @click="saveEditRequirement"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-danger"
                class="mr-50"
                @click="discardEditRequirement"
              >
                Discard
              </b-button>
            </b-col>
          </b-row>
        </b-tab> <!-- Requirement -->
        <b-tab title="Allocations">
          <div>
            <h3>Allocation for {{ selected_requirement.properties.display_id }}</h3>
            <Allocation />
          </div>
        </b-tab> <!-- Allocation -->
        <b-tab title="Associations">
          <app-collapse class="mt-2">
            <requirement-details-sidebar-collapse
              title="Behaviour Trees"
              :items="selected_requirement.bt_views || []"
              type="bt"
            />
            <requirement-details-sidebar-collapse
              title="Issues"
              :items="selected_requirement.uncertainties || []"
              type="uncertainties"
            />
            <requirement-details-sidebar-collapse
              title="Tests"
              :items="selected_requirement.tests || []"
              type="test"
            />
            <requirement-details-sidebar-collapse
              title="Integration"
              :items="selected_requirement.interfaces || []"
              type="interfaces"
            />
            <requirement-details-sidebar-collapse
              title="Refinement by Nodes"
              :items="selected_requirement.applications || []"
            />
            <requirement-details-sidebar-collapse
              title="Notes"
              :items="selected_requirement.notes || []"
              type="notes"
            />
          </app-collapse>
        </b-tab> <!-- Other Associations -->
        <b-tab title="Trace">
          <div>
            <h3>Trace for {{ selected_requirement.properties.display_id }}</h3>
            <ReqTrace />
          </div>
        </b-tab> <!-- Other Associations -->
        <!-- Maybe add a tab for editing other attributes
        <b-tab title="Other Attributes">
        </b-tab>
        -->
      </b-tabs>

    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { apiStatus } from '@/enums'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RequirementDetailsSidebarCollapse from '@/views/RequirementsTableLegacy/components/DetailsSidebarCollapse.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import Allocation from '@/views/RequirementsTableLegacy/forms/ReqAllocation.vue'
import ReqTrace from '@/views/RequirementsTableLegacy/forms/ReqTrace.vue'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'

export default {
  name: 'RequirementDetailsSidebar',
  directives: {
    Ripple,
  },
  components: {
    TipTapEditor,
    AppCollapse,
    RequirementDetailsSidebarCollapse,
    VuePerfectScrollbar,
    Allocation,
    LabelWithHoverIcon,
    ReqTrace,
  },
  props: {
    isSidebar: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      apiStatus,
      isEditingRequirementObjectText: false,
      requirementObjectText: '',
      section: '',
      priority: '',
      classification: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
      selected_requirement_status: state => state.requirements.selected_requirement_status,
    }),
    ...mapGetters({
      priority_options: 'constants/requirementPriorities',
    }),
  },
  watch: {
    selected_requirement: {
      handler() {
        if (this.selected_requirement.properties.object_text !== undefined) {
          this.requirementObjectText = this.selected_requirement.properties.object_text
          this.section = this.selected_requirement.properties.section
          this.priority = this.selected_requirement.properties.priority
          this.classification = this.selected_requirement.properties.classification
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.requirementObjectText = this.selected_requirement.properties.object_text
    this.section = this.selected_requirement.properties.section
    this.priority = this.selected_requirement.properties.priority
    this.classification = this.selected_requirement.properties.classification
  },
  beforeDestroy() {
    this.requirementObjectText = ''
    this.section = ''
    this.priority = ''
    this.classification = ''
  },
  methods: {
    saveEditRequirement() {
      const payload = {
        object_text: this.requirementObjectText,
        priority: this.priority,
        classification: this.classification,
        section: this.section,
      }
      this.$store
        .dispatch('requirements/patchRequirement', payload)
        .then(() => {
          this.isEditingRequirementObjectText = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirement updated',
              icon: 'EditIcon',
              text: this.selected_requirement.properties.display_id,
              variant: 'success',
            },
          })
        })
    },
    discardEditRequirement() {
      if (this.requirementObjectText !== this.selected_requirement.properties.object_text) {
        this.$bvModal
          .msgBoxConfirm(
            'You have unsaved changes. Are you sure you wish to discard your changes to this requirement?', {
              title: 'Unsaved changes',
              okVariant: 'outline-danger',
              okTitle: 'Discard changes',
              cancelTitle: 'Cancel, keep editing',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
              noFade: true,
            },
          )
          .then(confirm => {
            if (confirm) {
              // Discard changes and revert back to non-editing state
              this.requirementObjectText = this.selected_requirement.properties.object_text
              this.section = this.selected_requirement.properties.section
              this.priority = this.selected_requirement.properties.priority
              this.classification = this.selected_requirement.properties.classification
              this.isEditingRequirementObjectText = false
            }
          })
      } else {
        this.isEditingRequirementObjectText = false
      }
    },
    handleEnter(e) {
      if (e.ctrlKey) {
        this.saveEditRequirement()
      }
    },
    openHistory() {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.selected_requirement.properties.id,
          },
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

body.dark-layout {
  // Loading panel background colour
  .b-overlay {
    .bg-light {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
.scrollable-thing{
  overflow-y: auto;
  max-height: 40%;
}
</style>
