<template>
  <b-list-group-item
    v-b-tooltip.hover.top="`Click to open in behaviour tree`"
    button
    @click="routeToBehaviourView"
  >
    <div class="w-100 d-inline-flex justify-content-between">
      <div class="d-inline-flex">
        <span class="text-primary font-weight-bolder mr-2">
          {{ bt.properties.name }}
        </span>
        <div>
          <b-badge class="mr-50" variant="info">
            {{ bt.bns.length }}
            <span v-if="bt.bns.length === 1">node</span>
            <span v-else>nodes</span>
          </b-badge>
        </div>
      </div>
      <div class="mr-50">
        <small
          v-if="bt.properties.updated"
          v-b-tooltip.hover.top="`Updated at ${bt.properties.updated}  -  Created at ${bt.properties.created} `"
        >
          Updated {{ bt.properties.updated | diffForHumans }}
        </small>
        <small v-else v-b-tooltip.hover.top="`Created at ${bt.properties.created}`">
          Created {{ bt.properties.created | diffForHumans }}
        </small>
      </div>
    </div>

    <div v-if="bt.properties.description" class="mt-50 ml-50">
      {{ bt.properties.description }}
    </div>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ListGroupItemBT',
  props: {
    bt: {
      required: true,
      type: Object,
    },
  },
  methods: {
    routeToBehaviourView() {
      this.$store.dispatch('behaviours/selectBehaviourTree', this.bt.properties.id)
        .then(data => {
          if (this.bt.bns.length > 0) {
            this.$router.push(
              {
                name: 'joint_mbse_tree_focus',
                params: { behaviourTreeId: data.id },
                query: { focus: this.bt.bns[0].id },
              },
            )
          } else {
            this.$router.push({
              name: 'joint_mbse_tree',
              params: { },
            })
          }
        })
    },
  },
}
</script>
