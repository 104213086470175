<template>
  <div id="requirements-page-container">
    <page-details v-model="show_details">
      <div id="requirements-action-bar">
        <div class="mb-50">
          <b-badge variant="primary">
            {{ modeLabel }}
          </b-badge>
          <b-badge
            v-if="selected_requirements.length > 0"
            variant="info"
            class="ml-50 animate-pulse"
          >
            {{ selected_requirements.length }} Requirements selected
          </b-badge>
          <span v-if="mode === 'snapshot_compare'">
            <feather-icon icon="CodeIcon" />
            <b-badge>{{ snapshot.snapshot_name }}</b-badge>
          </span>
        </div>
        <div class="w-100 mb-50 d-inline-flex justify-content-between">
          <span>
            <v-select
              :value="selected_specification"
              :options="specifications"
              :reduce="spec => spec.id"
              class="mr-1 d-inline-block"
              style="min-width: 20vw"
              label="title"
              show-all
              @input="changeSpecification"
            />
            <b-button
              class="d-inline-block"
              variant="outline-secondary"
              size="sm"
              @click="$bvModal.show('show-hide-columns-modal')"
            >
              <feather-icon icon="ColumnsIcon" />
              Show/Hide Columns
            </b-button>
            <div v-if="mode === 'snapshot_compare'" style="border: 1px solid grey; display: inline-block; border-radius: 100px" class="m-1 px-1">
              Added - <span class="row-added" style="width: 10px; height: 10px; display: inline-block" /> |
              Modified - <span class="row-modified" style="width: 10px; height: 10px; display: inline-block" /> |
              Deleted - <span class="row-deleted-color" style="width: 10px; height: 10px; display: inline-block" />
            </div>
          </span>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-icon mr-50"
              @click="refreshClicked"
            >
              <feather-icon icon="RefreshCwIcon" />
            </b-button>

            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
            >
              <template v-slot:button-content>
                <feather-icon icon="MenuIcon" />
                Menu
              </template>
              <b-dropdown-item @click="$bvModal.show('section-navigation-modal')">
                Go-to Section
              </b-dropdown-item>
              <b-dropdown-item @click="$bvModal.show('show-hide-columns-modal')">
                Show / Hide Columns
              </b-dropdown-item>
              <b-dropdown-item v-if="mode === 'document'" @click="setMode({ mode:'backlog'})">
                Switch to Backlog Mode
              </b-dropdown-item>
              <b-dropdown-item v-else-if="mode === 'backlog' || mode === 'snapshot_compare'" @click="setMode({ mode: 'document' })">
                Switch to Document Mode
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item v-if="selected_requirements.length" @click="$bvModal.show('bulk-update-priority')">
                Bulk Update Priority
              </b-dropdown-item>
              <b-dropdown-item v-if="selected_requirements.length" @click="$bvModal.show('bulk-update-verification_method')">
                Bulk Update Verification Methods
              </b-dropdown-item>
              <b-dropdown-item @click="$bvModal.show('snapshot-compare-modal')">
                Compare Snapshot
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="$bvModal.show('import-requirements-modal')">
                Import Requirements
              </b-dropdown-item>
              <b-dropdown-item @click="exportRequirements()">
                Export Requirements
              </b-dropdown-item>
              <b-dropdown-item @click="exportTrace()">
                Export Trace
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item @click="$store.commit('requirementsTableLegacy/SET_SHOW_DELETED', !show_deleted)">
                <span class="mt-1" style="display: inline-block">
                  <b-checkbox
                    :checked="show_deleted"
                    switch
                    style="display: inline"
                    @change="$store.commit('requirementsTableLegacy/SET_SHOW_DELETED', !show_deleted)"
                  >
                    Show Deleted Requirements
                  </b-checkbox>
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button-group v-if="!show_details" class="ml-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                @click="requirement_relationship = null; $bvModal.show('add-requirement-modal')"
              >
                <feather-icon icon="PlusIcon" />
                Create Requirement
              </b-button>
              <b-button
                :disabled="selected_requirement.properties.id === 'undefined'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="show_details = true"
              >
                Show Details
              </b-button>
            </b-button-group>
          </div>
        </div>

        <RequirementsTable
          @go-to-requirement="req => goToRequirement(req, false)"
          @table-updated="tableUpdated"
        />
      </div>

      <template v-slot:details>
        <RequirementDetails
          id="requirements-page-sidebar"
          @relationship-type="val => { requirement_relationship = val }"
        />
      </template>
    </page-details>

    <!-- Modals-->
    <div id="requirements-page-modals-container">
      <add-requirement-modal :parent="selected_requirement.properties" :relation-type="requirement_relationship" parent_rel="sibling" />
      <decompose-functions-modal @parsed="goToRequirement(selected_requirement.properties.id)" />
      <move-requirement-modal @ok="fetchRequirements" />
      <copy-requirement-modal @ok="fetchRequirements" />
      <edit-requirement-modal />
      <delete-requirement-modal />
      <div v-if="selected_requirement.properties.id">
        <impact-requirement-modal
          :requirement-id="selected_requirement.properties.id"
          :properties="selected_requirement.properties"
          @go-to-requirement="id => { goToRequirement(id); $bvModal.hide('impact-requirement-modal') }"
        />
      </div>
      <import-requirement-modal />
      <export-requirement-modal />

      <trace-forward-modal :selected-requirements="requirementSelection" />
      <trace-backward-modal :selected-requirements="requirementSelection" />
      <trace-export-modal />

      <add-coverage-table-modal :requirement="selected_requirement.properties" :spec="selected_specification" />
      <parse-requirement-modal @parsed="goToRequirement(selected_requirement.properties.id)" />
      <show-hide-columns-modal v-model="visible_columns" :mode="viewing_mode" />
      <section-navigation-modal
        :specification-id="selected_specification || ''"
        @go-to-requirement="goToRequirement"
      />
      <create-note-modal :parent-ids="[selected_requirement.properties.id]" />
      <associate-tests-modal />
      <add-interface-table-modal :requirement="selected_requirement.properties" :spec="selected_specification" />
      <snapshot-compare-modal />
      <bulk-update-modal property="priority" />
      <bulk-update-modal property="verification_method" />
      <!-- <relationship-modal /> -->
    </div>
    <!-- ./Modals -->
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapState, mapActions, mapGetters } from 'vuex'
import coreService from '@/libs/api-services/core-service'
import { apiStatus } from '@/enums'
import vSelect from 'vue-select'

import PageDetails from '@/components/Pages/Details.vue'
import CreateNoteModal from '@/components/Notes/Modals/CreateNoteModal.vue'
import RequirementDetails from '@/views/RequirementsTableLegacy/components/DetailsSidebar.vue'

import AddCoverageTableModal from './modals/AddCoverageTable.vue'
import AddInterfaceTableModal from './modals/AddInterfaceTable.vue'
import BulkUpdateModal from './modals/BulkUpdate.vue'
import RequirementModalAssociateTests from './modals/AllocateTests.vue'
import RequirementSnapshotCompare from './modals/SnapshotCompare.vue'
import RequirementsModalAdd from './modals/Add.vue'
import RequirementsModalCopy from './modals/Copy.vue'
import RequirementsModalDecompose from './modals/DecomposeFunctions.vue'
import RequirementsModalDelete from './modals/Delete.vue'
import RequirementsModalEdit from './modals/Edit.vue'
import RequirementsModalExport from './modals/Export.vue'
import RequirementsModalImpact from './modals/ChangeImpactAnalysis.vue'
import RequirementsModalImport from './modals/Import.vue'
import RequirementsModalMove from './modals/Move.vue'
import RequirementsModalParse from './modals/Parse.vue'
import RequirementsTable from './components/RequirementsTable.vue'
import SectionNavigation from './modals/SectionNavigation.vue'
import ShowHideColumnsModal from './modals/ShowHideColumns.vue'
import TraceBackwardModal from './modals/TraceBackwardModal.vue'
import TraceExportModal from './modals/TraceExportModal.vue'
import TraceForwardModal from './modals/TraceForwardModal.vue'
// import RequirementsModalRelationship from './modals/Relationship.vue'

export default {
  name: 'RequirementsTableLegacy',
  directives: { Ripple },
  components: {
    vSelect,
    RequirementsTable,
    AddCoverageTableModal,
    AddInterfaceTableModal,
    PageDetails,
    RequirementDetails,
    CreateNoteModal,
    AddRequirementModal: RequirementsModalAdd,
    AssociateTestsModal: RequirementModalAssociateTests,
    CopyRequirementModal: RequirementsModalCopy,
    DeleteRequirementModal: RequirementsModalDelete,
    EditRequirementModal: RequirementsModalEdit,
    ExportRequirementModal: RequirementsModalExport,
    ImpactRequirementModal: RequirementsModalImpact,
    ImportRequirementModal: RequirementsModalImport,
    MoveRequirementModal: RequirementsModalMove,
    ParseRequirementModal: RequirementsModalParse,
    SectionNavigationModal: SectionNavigation,
    SnapshotCompareModal: RequirementSnapshotCompare,
    ShowHideColumnsModal,
    DecomposeFunctionsModal: RequirementsModalDecompose,
    // RelationshipModal: RequirementsModalRelationship,
    BulkUpdateModal,
    TraceForwardModal,
    TraceBackwardModal,
    TraceExportModal,
  },
  data() {
    return {
      show_details: false,
      initial_specification: null,
      initial_visible_columns: ['section', 'id', 'object_text', 'priority'],
      visible_columns: [],
      requirement_relationship: null,
      NotesModalEdit: null,
      viewing_mode: 'document',
      apiStatus,
      gotoReq: null,
    }
  },
  computed: {
    ...mapState({
      specifications: state => state.specification.list,
      specifications_status: state => state.specifications.specifications_status,
      show_deleted: state => state.requirementsTableLegacy.show_deleted,
      state_selected_specification: state => state.requirementsTableLegacy.specification,
      selected_requirement: state => state.requirements.selected_requirement,
      columns: state => [...state.requirements.requirements_fields, { field: 'sequence', label: 'Sequence' }],
      mode: state => state.requirementsTableLegacy.mode,
      layout_status: state => state.requirementsTableLegacy.layout_status,
      data_status: state => state.requirementsTableLegacy.data_status,
      snapshot: state => state.requirementsTableLegacy.snapshot,
      selected_requirements: state => state.requirements.selected_requirements,
    }),
    selected_specification() {
      return this.state_selected_specification || this.$router.currentRoute.params.specId
    },
    ...mapGetters({
      modeLabel: 'requirementsTableLegacy/modeLabel',
    }),
    requirementSelection() {
      // Use this is shit code until this section is refactored,
      //  basically it's just used to give the Trace modal a consistent array of selected requirement(s)
      if (this.selected_requirements.length > 0) {
        return this.selected_requirements
      }
      return [{
        id: this.selected_requirement.properties.id,
        display_id: this.selected_requirement.properties.display_id,
        trace: this.selected_requirement.trace,
      }]
    },
  },
  watch: {
    selected_requirements(newVal = []) {
      if (newVal.length > 0) {
        this.show_details = false
      }
    },
    '$route.query': {
      immediate: true,
      async handler(query) {
        if (query) {
          if (!this.$route.params.specId) {
            const fullReq = await coreService.requirementsApi.getRequirement(query.focus, this.$route.params.modelId)
            const specId = fullReq.spec_id
            this.$router.push({ name: 'legacy_requirements_table_focus_on_id', params: { specId }, query: { focus: query.focus } })
            this.changeSpecification(specId)
            this.goToRequirement(query.focus, this.show_details)
            return
          }
          await this.selectRequirement(query.focus)
          if (!this.specifications || this.specifications.length < 1) {
            this.changeSpecification(this.selected_requirement.properties.spec_id)
          }
          this.goToRequirement(this.selected_requirement.properties.id, this.show_details)
        }
      },
    },
    table_loaded: {
      immediate: true,
      handler(val) {
        if (val && this.selected_requirement) {
          this.$nextTick(() => this.goToRequirement(this.selected_requirement.properties.id))
        }
      },
    },
  },
  beforeMount() {
    this.$store.dispatch('requirements/clearSelectedRequirement')
  },
  async mounted() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    if (!this.specifications || this.specifications.length < 1) {
      await store.dispatch('specification/getAllSpecifications', { modelId: this.$route.params.modelId })
    }
    if (!this.state_selected_specification && this.$route.params.specId) {
      await store.dispatch('requirementsTableLegacy/setSpecification', this.$route.params.specId)
    }
  },
  beforeDestroy() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
    this.$store.commit('requirements/CLEAR_ALL')
    this.$store.commit('requirementsTableLegacy/CLEAR_ALL')
  },
  methods: {
    ...mapActions({
      selectRequirement: 'requirements/selectRequirement',
      setMode: 'requirementsTableLegacy/setMode',
      vuexSetSpecification: 'requirementsTableLegacy/setSpecification',
      toggleShowDeleted: 'requirementsTableLegacy/toggleShowDeleted',
    }),
    tableUpdated(selectedRequirementIds) {
      // event received from child component requirementsTable
      const reqId = this.gotoReq || selectedRequirementIds?.length > 0 ? selectedRequirementIds[0] : false
      if (reqId) {
        // requirements have been loaded so element can be scrolled to view
        this.goToRequirement(reqId, this.show_details, true)
      }
    },
    changeSpecification(specId) {
      if (specId) {
        this.vuexSetSpecification(specId)
        this.$router.push({
          name: 'legacy_requirements_table',
          params: { ...this.$route.params, specId },
        })
      }
    },
    refreshClicked() {
      this.$store.commit('requirementsTableLegacy/CLEAR_ALL')
      this.fetchRequirements()
    },
    fetchRequirements() {
      if (this.state_selected_specification !== this.selected_specification) {
        this.changeSpecification(this.selected_specification)
        return
      }
      store
        .dispatch('requirementsTableLegacy/getData', { setLayout: true, modelId: this.$route.params.modelId })
        .then(() => {
          this.goToRequirement(this.$route.query.focus, false)
        })
    },
    goToRequirement(id, showDetails = true, scrollOnly = false) {
      const domElement = document.getElementById(`requirement-${id}`) || null
      if (domElement) {
        domElement.scrollIntoView({ block: 'center' })
        if (id && !scrollOnly) {
          this.selectRequirement(id).then(() => {
            this.show_details = showDetails
          })
        }
        document.documentElement.scrollTop = 0
        this.gotoReq = ''
      } else {
        this.gotoReq = id
      }
    },
    exportRequirements() {
      this.$bvModal.show('export-requirements-modal')
    },
    exportTrace() {
      this.$bvModal.show('trace-export-modal')
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
