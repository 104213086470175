<template>
  <div>
    <b-row class="mt-75">
      <b-col>
        <list-group-requirement-trace
          :label="`Backward trace from ${selected_requirement.properties.display_id}`"
          :items="fields.trace"
          modal-id="backward-trace-requirement-modal"
        />
      </b-col>
    </b-row>
    <b-row class="mt-75">
      <b-col>
        <list-group-requirement-trace
          is-forward-trace
          :label="`Forward trace from ${selected_requirement.properties.display_id}`"
          :items="fields.coverage"
          modal-id="forward-trace-requirement-modal"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ListGroupRequirementTrace from '@/components/Forms/ListGroups/ListGroupRequirementTrace.vue'

export default {
  components: {
    ListGroupRequirementTrace,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: '',
      name: '',
      acronym: '',
      abstract: false,
      multiplicity: '',
      stereotype_selected: [],
      description: '',
      validity: 'Valid',
      context_data: { attributes: {} },
      fields: {
        trace: [],
        coverage: [],
      },
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
    }),
  },
  watch: {
    selected_requirement(newVal) {
      this.fillFields(newVal)
    },
  },
  mounted() {
    this.$store.dispatch('domainModel/getComponents')
    this.$store.dispatch('releases/getReleases')
    this.fillFields(this.selected_requirement)
  },
  methods: {
    fillFields(n) {
      const t = this.fields
      t.trace = n.trace || []
      t.coverage = n.coverage || []
    },
  },
}
</script>
