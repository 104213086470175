<template>
  <b-modal
    id="impact-requirement-modal"
    title="Change Impact Analysis"
    ok-only
    ok-variant="outline-primary"
    ok-title="Close"
    lazy
    static
    @show="onShow"
    @hide="onHide"
  >
    <div v-if="result">
      <b-row>
        <b-col class="mx-auto">
          <feather-icon
            v-b-popover.hover.html.left="'<i>This section outlines all Functions/Tests/Performers/Resources/Requirements that are related to the requirement ' + result.properties.display_id + '.<br><br>' +
              'Actions such as modifying or deleting this requirement would have an effect on the impacted items below.</i>'"
            icon="HelpCircleIcon"
            size="20"
            class="float-right text-info"
          />
          <div style="text-align:center">
            <span class="text-primary font-medium-3 font-weight-bolder">{{ result.properties.display_id }}</span>
            [<span class="mx-25 font-small-3">{{ result.properties.priority }}</span>]

            <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="scroll-area-idea-entities"
              style="max-height: 15rem"
            >
              <p
                class="font-small-3"
                v-sanitized-html="result.properties.object_text"
              />
            </vue-perfect-scrollbar>
            <hr>
          </div>
        </b-col>
      </b-row>
      <b-tabs justified class="mx-50 mb-2">
        <!-- Impacted Objectives -->
        <b-tab>
          <template #title>
            <b-badge
              :variant="resolveBadgeVariant(countObjectives)"
              class="mr-50"
            >
              {{ countObjectives }}
            </b-badge>
            <span>Objective{{ countObjectives | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <impact-list-group
                title="Objectives"
                :high-impact-items="result.objectives.filter(obj => obj.impact === 'last')"
                :impact-items="result.objectives.filter(obj => obj.impact !== 'last')"
              >
                <template v-slot:high-impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" /><br>
                  <small>
                    This objective will not be satisfiable.
                  </small>
                </template>
                <template v-slot:impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" /><br>
                  <small>
                    The ability to meet this objective may be impacted.
                  </small>
                </template>
              </impact-list-group>
            </b-col>
          </b-row>
        </b-tab>
        <!-- ./Impacted Objectives -->

        <!-- Impacted Functions -->
        <b-tab>
          <template #title>
            <b-badge
              :variant="resolveBadgeVariant(countFunctions)"
              class="mr-50"
            >
              {{ countFunctions }}
            </b-badge>
            <span>Function{{ countFunctions | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <impact-list-group
                title="Impacted Functions"
                :high-impact-items="result.functions.filter(fun => fun.impact === 'last')"
                :impact-items="[...result.impacted_fns.map(fun => Object({...fun, im_fun:true})), ...result.functions.filter(fun => fun.impact !== 'last').map(fun => Object({...fun, im_fun:false}))]"
              >
                <template v-slot:high-impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" /><br>
                  <small>
                    This function will be unavailable/critically impacted.
                  </small>
                </template>
                <template v-slot:impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <br>
                  <small v-if="item.impact === 'performance'">
                    The <strong>performance</strong> of this function may be impacted.
                  </small>
                  <small v-else-if="item.im_fun">
                    This function may be impacted.
                  </small>
                  <small v-else>
                    This function will be impacted.
                  </small>
                </template>
              </impact-list-group>

              <div
                v-if="result.bts.length"
                class="mt-1"
              >
                <h6>Show in behaviour tree(s)</h6>
                <ul>
                  <li v-for="(fn_bts, index) in result.bts" :key="`fn_bts-${index}`">
                    <strong>
                      <b-link
                        target="_blank"
                        @click="openInBehaviourTree(fn_bts.id, result.bns)"
                      >
                        {{ fn_bts.name }}
                      </b-link>
                    </strong>
                    <!--            TODO: Link together with function flow when exists    -->
                    <!--            <a :href="fn_bts" target="_blank" style="float:right">[Function Flow]</a>-->
                  </li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <!-- ./Impacted Functions -->

        <!-- Impacted Interfaces -->
        <b-tab>
          <template #title>
            <b-badge
              :variant="resolveBadgeVariant(countInterfaces)"
              class="mr-50"
            >
              {{ countInterfaces }}
            </b-badge>
            <span>Interface{{ countInterfaces | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <impact-list-group
                title="Impacted Interfaces"
                :high-impact-items="result.interfaces.filter(int => int.impact === 'last')"
                :impact-items="result.interfaces.filter(int => int.impact !== 'last')"
              >
                <template v-slot:high-impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <small>This interface will be critically impacted. </small><br>
                  <h6 v-if="item.flow_on.requirements.length">
                    This could subsequently impact the following <strong>Requirements</strong>
                  </h6>
                  <ul>
                    <li
                      v-for="(req_flow, index) in item.flow_on.requirements"
                      :key="`req_flow-${index}`"
                    >
                      <!--              TODO implement requirement link here-->
                      <b-link>{{ req_flow.display_id }}</b-link>
                    </li>
                  </ul>
                  <h6 v-if="item.flow_on.functions.length">
                    <hr>
                    This could subsequently impact the following <strong>Functions</strong>
                  </h6>
                  <ul>
                    <li
                      v-for="(fun_flow, index) in item.flow_on.functions"
                      :key="`fun_flow-${index}`"
                    >
                      <entity-link :entity-id="fun_flow.id" />
                    </li>
                  </ul>
                </template>
                <template v-slot:impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <br>
                  <small v-if="item.impact === 'performance'">
                    The <strong>performance</strong> of this interface may be impacted.
                  </small>
                  <small v-else>
                    This interface will be impacted.
                  </small>
                  <h6 v-if="item.flow_on.requirements.length">
                    This could subsequently impact the following <strong>Requirements</strong>
                  </h6>
                  <ul>
                    <li
                      v-for="(req_flow, index) in item.flow_on.requirements"
                      :key="`req_flow-${index}`"
                    >
                      <!--              TODO implement requirement link here-->
                      <b-link>{{ req_flow.id }}</b-link>
                    </li>
                  </ul>
                  <h6 v-if="item.flow_on.functions.length">
                    <hr>
                    This could subsequently impact the following <strong>Functions</strong>
                  </h6>
                  <ul>
                    <li
                      v-for="(fun_flow, index) in item.flow_on.functions"
                      :key="`fun_flow-${index}`"
                    >
                      <entity-link :entity-id="fun_flow.id" />
                    </li>
                  </ul>
                </template>
              </impact-list-group>
            </b-col>
          </b-row>
        </b-tab>
        <!-- ./Impacted Interfaces -->

        <!-- Impacted Test Cases -->
        <b-tab>
          <template #title>
            <b-badge
              :variant="resolveBadgeVariant(countTests)"
              class="mr-50"
            >
              {{ countTests }}
            </b-badge>
            <span>Test Case{{ countTests | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <impact-list-group
                title="Impacted Tests"
                :high-impact-items="result.tests"
                :impact-items="result.impacted_tests"
              >
                <template v-slot:high-impact-item="{ item }">
                  <!--          TODO implement test link-->
                  <b-link>{{ item.display_id }}</b-link>
                  <br>
                  <small>This test case will be critically impacted.</small>
                </template>
                <template v-slot:impact-item="{ item }">
                  <!--          TODO implement test link-->
                  <b-link>{{ item.display_id }}</b-link>
                  <br>
                  <small>This test case should be part of the regression test suite for this change.</small>
                </template>
              </impact-list-group>
            </b-col>
          </b-row>
        </b-tab>
        <!-- ./Impacted Test Cases -->

        <!-- Impacted Performers -->
        <b-tab>
          <template #title>
            <b-badge
              :variant="resolveBadgeVariant(countPerformers)"
              class="mr-50"
            >
              {{ countPerformers }}
            </b-badge>
            <span>Performer{{ countPerformers | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <impact-list-group
                title="Impacted Performers"
                :high-impact-items="result.performers.filter(per => per.impact === 'last')"
                :impact-items="result.performers.filter(per => per.impact !== 'last')"
              >
                <template v-slot:high-impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <br>
                  <small>This performer will not have any functions to perform, or the only function they perform will be critically impacted.</small>
                </template>
                <template v-slot:impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <br>
                  <small>This performer will be impacted.</small>
                </template>
              </impact-list-group>
            </b-col>
          </b-row>
        </b-tab>
        <!-- ./Impacted Performers -->

        <!-- Impacted Resources -->
        <b-tab>
          <template #title>
            <b-badge
              :variant="resolveBadgeVariant(countResources)"
              class="mr-50"
            >
              {{ countResources }}
            </b-badge>
            <span>Resource{{ countResources | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <impact-list-group
                title="Impacted Resources"
                :high-impact-items="result.resources.filter(per => per.impact === 'last')"
                :impact-items="result.resources.filter(per => per.impact !== 'last')"
              >
                <template v-slot:high-impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <br>
                  <small> This resource will be critically impacted.</small>
                </template>
                <template v-slot:impact-item="{ item }">
                  <entity-link :entity-id="item.id" :text="item.name" />
                  <br>
                  <small>This resource will be impacted.</small>
                </template>
              </impact-list-group>
            </b-col>
          </b-row>
        </b-tab>
        <!-- ./Impacted Resources -->

        <!-- Other -->
        <b-tab>
          <template #title>
            <b-badge
              class="mr-50"
              :variant="resolveBadgeVariant(countRequirements)"
            >
              {{ countRequirements }}
            </b-badge>
            <span>Requirement{{ countRequirements | handlePluralSuffix }}</span>
          </template>
          <b-row>
            <b-col>
              <b-form-group
                label="Higher level requirements impacted through traceability"
                label-for="v-higher-level-requirements"
              >
                <b-form-select
                  id="v-higher-level-requirements"
                  v-model="selected_requirement"
                  :select-size="10"
                  :options="requirementsToOptions(result.trace)"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Lower level requirements impacted through traceability"
                label-for="v-lower-level-requirements"
              >
                <b-form-select
                  id="v-lower-level-requirements"
                  v-model="selected_requirement"
                  :select-size="10"
                  :options="requirementsToOptions(result.coverage)"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Potentially impacted by extension"
                label-for="v-impact-by-extension"
              >
                <b-form-select
                  id="v-impact-by-extension"
                  v-model="selected_requirement"
                  :select-size="10"
                  :options="requirementsToOptions(result.extended_impacted_requirements)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-if="selected_requirement"
            class="float-right"
            variant="primary"
            @click="goToRequirement"
          >
            Go to Requirement {{ selected_requirement }}
          </b-button>
        </b-tab>
        <!-- ./Other -->
      </b-tabs>
    </div>
    <div v-else style="text-align: center">
      <b-spinner class="mt-1 mb-1" variant="primary" />
      <p>Calculating the change impact for requirement:
        <span class="text-primary font-weight-bolder">{{ requirementDisplayID }}</span>
      </p>
    </div>
  </b-modal>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import RequirementFormImpactListGroup from '@/views/RequirementsTableLegacy/forms/ImpactListGroup.vue'
import FormEntityLink from '@/components/Forms/EntityLink.vue'

export default {
  name: 'ChangeImpactAnalysisModal',
  components: {
    BFormSelect,
    VuePerfectScrollbar,
    ImpactListGroup: RequirementFormImpactListGroup,
    EntityLink: FormEntityLink,
  },
  props: {
    requirementId: {
      type: String,
      required: true,
    },
    properties: {
      required: true,
    },
  },
  data() {
    return {
      result: null,
      status: false,
      selected_requirement: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
    }
  },
  computed: {
    requirement() { return this.requirementId },
    requirementDisplayID() { return this.properties.display_id },
    countObjectives() { return this.result.objectives.length },
    countFunctions() { return this.result.functions.length + this.result.impacted_fns.length },
    countInterfaces() { return this.result.interfaces.length },
    countTests() { return this.result.tests.length + this.result.impacted_tests.length },
    countPerformers() { return this.result.performers.length },
    countResources() { return this.result.resources.length },
    countRequirements() { return this.result.trace.length + this.result.coverage.length + this.result.extended_impacted_requirements.length },
  },
  methods: {
    onShow() {
      this.status = false
      const params = { model: this.$store.state.model.id }
      this.$http
        .get(`/api/v2/requirement/${this.requirement}/impact_analysis`, { params })
        .then(({ data }) => {
          this.result = data
          this.status = true
        })
        .catch(e => {
          console.error(`[ChangeImpactAnalysis] Failed to calculate change impact for requirement: ${this.requirement}`)
          console.error(e)
          this.status = false
        })
    },
    onHide() {
      this.result = null
      this.selected_requirement = null
      this.status = false
    },
    goToRequirement() {
      this.$emit('go-to-requirement', this.selected_requirement)
      this.$bvModal.hide('impact-requirement-modal')
    },
    requirementsToOptions(requirements) {
      return requirements.map(req => Object({
        value: req.id,
        text: req.display_id,
      }))
    },
    openInBehaviourTree(btId, bns) {
      this.$store
        .dispatch('behaviours/selectBehaviourTree', btId)
        .then(() => {
          if (bns && bns.length > 0) {
            this.$router.push({
              name: 'joint_mbse_tree_focus',
              params: { behaviourTreeId: btId },
              query: { focus: bns[0] },
            })
          } else {
            this.$router.push({
              name: 'joint_mbse_tree',
              params: { },
            })
          }
        })
    },
    resolveBadgeVariant(value) {
      if (value > 0) {
        return 'danger'
      }
      return 'secondary'
    },
  },
}
</script>

<style lang="scss">
#impact-requirement-modal > .modal-dialog {
  max-width: 70%;
}
</style>
