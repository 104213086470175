<template>
  <b-link>{{ text ? text : entityId }}</b-link>
</template>

<script>
export default {
  name: 'EntityLink',
  props: {
    entityId: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
