<template>
  <b-modal
    id="section-navigation-modal"
    title="Section Navigator"
    size="lg"
    ok-title="Go to Section"
    :ok-disabled="!selectedReq"
    ok-variant="success"
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="$emit('go-to-requirement', selectedReq)"
  >
    <p class="mb-1 font-small-3">
      Use this window to quickly navigate to a section within this specification.
    </p>
    <hr>

    <div v-if="requirements.length > 0">
      <b-form-input
        v-model="filterQuery"
        type="search"
        placeholder="Filter sections..."
      />
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        style="max-height: 70vh"
      >
        <b-table
          v-if="requirements.length"
          :fields="fields"
          :items="requirements"
          :filter="filterQuery"
          hover
          selectable
          select-mode="single"
          thead-class="d-none"
          selected-variant="dark"
          @row-selected="selectRequirement"
        />
      </vue-perfect-scrollbar>
    </div>
    <div v-else>
      <p>
        <span class="text-warning">There are no section headings defined in this specification.</span>
        To define a section heading, set the 'Priority' of a requirement to 'Heading'.
      </p>
    </div>
  </b-modal>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'SectionNavigationModal',
  components: {
    VuePerfectScrollbar,
  },
  setup() {
    const requirements = computed(() => store.state.requirementsTable.allRequirements)

    const selectedReq = ref(null)
    const filterQuery = ref('')

    const fields = ref([
      { key: 'section', tdClass: 'text-nowrap' },
      { key: 'display_id', tdClass: 'text-nowrap' },
      { key: 'id', tdClass: 'text-nowrap' },
    ])

    const perfectScrollbarSettings = {
      maxScrollbarLength: 20,
      wheelPropagation: false,
    }

    const selectRequirement = row => {
      if (row[0]) {
        selectedReq.value = row[0].id
      }
    }

    return {
      requirements,
      fields,
      perfectScrollbarSettings,
      filterQuery,
      selectedReq,

      selectRequirement,
    }
  },
}
</script>
