<template>
  <b-list-group-item>
    <h5>{{ component.qualified_name }}</h5>
    <hr>
    {{ component.labels }}
    <!--<b-badge-->
    <!--  v-for="(label, label_index) in Array.from(component.labels)"-->
    <!--  :key="`label-${label_index}`"-->
    <!--&gt;-->
    <!--  {{ label }}-->
    <!--</b-badge>-->
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ComponentListGroupItem',
  props: {
    component: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
