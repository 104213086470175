<template>
  <div>
    <b-row>
      <b-col v-if="highImpactItems.length">
        <h5 class="text-danger">
          <feather-icon icon="AlertTriangleIcon" />
          High Impact
        </h5>
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in highImpactItems"
            :key="`high-im-${index}`"
            style="border-left: #ea5455 2px solid"
          >
            <slot name="high-impact-item" :item="item" />
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col v-if="impactItems.length">
        <h5 class="text-warning">
          <feather-icon icon="AlertTriangleIcon" />
          Warning
        </h5>
        <b-list-group>
          <b-list-group-item
            v-for="(item, index) in impactItems"
            :key="`im-${index}`"
            style="border-left: #ff9f43 3px solid"
          >
            <slot name="impact-item" :item="item" />
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col v-if="highImpactItems.length + impactItems.length === 0">
        <p
          class="ml-1 font-small-3"
        >
          No impacted {{ title }} were found.
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ImpactListGroup',
  props: {
    title: {
      type: String,
      required: true,
    },
    highImpactItems: {
      type: Array,
      required: true,
    },
    impactItems: {
      type: Array,
      required: true,
    },
  },
}
</script>
