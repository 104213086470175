<template>
  <b-modal
    id="forward-trace-requirement-modal"
    title="Forward Trace Requirement"
    size="xl"
    ok-title="Update Trace"
    ok-variant="success"
    cancel-variant="outline-secondary"
    cancel-title="Close"
    no-close-on-backdrop
    @ok="onSubmit"
  >
    <requirement-trace-form
      ref="traceForm"
      :is-modal="true"
      :requirement-ids="selectedRequirements.map(obj => obj.id)"
      :forward="true"
    />
  </b-modal>
</template>

<script>
import RequirementTraceForm from '@/components/Forms/RequirementTraceForm.vue'

export default {
  name: 'ForwardTraceModal',
  components: {
    RequirementTraceForm,
  },
  props: {
    selectedRequirements: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      this.$refs.traceForm.onSubmit()
    },
  },
}
</script>
