<template>
  <b-modal
    :id="`bulk-update-${property}`"
    :title="`Update ${selected_requirements.length} Requirements ${str_form(property)}`"
    ok-title="Bulk Edit"
    lazy
    @ok="update_requirement"
  >
    Update {{ str_form(property) }}'s of: <br>
    <strong v-for="({ display_id }, key) in selected_requirements" :key="`${key}-req`">
      <span v-if="key !== 0">, </span>{{ display_id }}
    </strong>
    <div v-if="property === 'priority'">
      <br>
      <b-form-select v-model="priority" :options="requirementPriorities" />
    </div>
    <div v-if="property === 'verification_method'">
      <br>
      <b-form-select v-model="verification_method" :options="verificationMethods" />
      <small>{{ verification_method_description[verification_method] || '' }}</small>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BulkUpdateRequirementModal',
  props: {
    property: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      priority: null,
      verification_method: null,
      verification_method_description: {},
    }
  },
  computed: {
    ...mapState({
      selected_requirements: state => state.requirements.selected_requirements,
    }),
    ...mapGetters({
      requirementPriorities: 'constants/requirementPriorities',
      verificationMethods: 'constants/qualificationMethods',
    }),
    // verificationMethodDescription: () => this.verificationMethods.find(el => el[this.verification_method] === this.verification_method).description,
  },
  watch: {
    requirementPriorities: {
      immediate: true,
      handler(vals) {
        this.priority = vals.find(val => val.default).id
      },
    },
    verificationMethods: {
      immediate: true,
      handler(vals) {
        this.verification_method = vals.find(val => val.default).id
        vals.forEach(val => {
          this.verification_method_description[val.id] = val.description
        })
      },
    },
  },
  methods: {
    str_form(str) {
      return str.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
    },
    update_requirement() {
      const requirement = {}
      requirement[this.property] = this.property === 'priority' ? this.priority : this.verification_method
      this.$store.dispatch('requirements/bulkEditRequirement', { ids: this.selected_requirements.map(({ id }) => id), requirement })
    },
  },
}
</script>
