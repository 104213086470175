<template>
  <div>
    <div v-if="showHeading">
      <b-button
        v-b-tooltip.hover.left="`Update trace links`"
        variant="flat-success"
        size="sm"
        class="mb-25"
        @click="$bvModal.show(modalId)"
      >
        <feather-icon icon="LinkIcon" />
      </b-button>

      <span class="font-medium-2">{{ label }}</span>
    </div>

    <b-list-group v-if="items.length">
      <ListGroupItemRequirementTrace
        v-for="(item, index) in items"
        :key="index"
        :is-forward-trace="isForwardTrace"
        :item="item"
      />
    </b-list-group>

    <div v-else>
      <span class="ml-2 font-small-3 text-muted">No linked {{ label }}</span>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import ListGroupItemRequirementTrace from '@/components/Forms/ListGroupItems/ListGroupItemRequirementTrace.vue'

export default {
  name: 'ListGroupRequirementTrace',
  components: {
    ListGroupItemRequirementTrace,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isForwardTrace: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    modalId: {
      type: String,
      required: true,
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
