<template>
  <b-modal
    id="trace-export-modal"
    title="Export Requirements Trace to file"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @ok="exportRequirements"
    @shown="onShown"
  >
    <b-row>
      <b-col>
        <div style="opacity: 0.8" class="mb-25">
          Select the Top Level Specification of the Trace
        </div>
        <b-form-group>
          <SpecificationFormSelect
            :value="to_spec"
            show-all
            @input="changeSpecificationTo"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <div style="opacity: 0.8" class="mb-25">
          Select the Lowest Level Specification of the Trace
        </div>
        <b-form-group>
          <SpecificationFormSelect
            :value="from_spec"
            show-all
            @input="changeSpecificationFrom"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div style="opacity: 0.8" class="mb-25">
          Additional Columns - Top Level
        </div>
        <v-select
          v-model="additional_columns_top"
          multiple
          label="title"
          class="v-select-style-overrides"
          :options="all_columns"
        >
          <template #option="{ title }">
            <span class="text-dark">
              {{ title }}
            </span>
          </template>
        </v-select>
      </b-col>
      <b-col>
        <div style="opacity: 0.8" class="mb-25">
          Additional Columns - Lower Level
        </div>
        <v-select
          v-model="additional_columns_low"
          multiple
          label="title"
          class="v-select-style-overrides"
          :options="all_columns"
        >
          <template #option="{ title }">
            <span class="text-dark">
              {{ title }}
            </span>
          </template>
        </v-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-checkbox
          v-model="show_text"
          class="custom-control-info"
        >
          Show text of Lower Level Requirements
        </b-form-checkbox>
      </b-col>
      <b-col cols="6">
        Trace Types to Export:
        <b-form-select
          v-model="trace_types"
          :options="trace_type_options"
          multiple
        />
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Exporting...
        </span>
        <span v-else>
          Export Trace
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axiosIns from '@/libs/axios'
import vSelect from 'vue-select'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpecificationFormSelect from '@/components/Specifications/Forms/Select.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
const FileSaver = require('file-saver')

export default {
  name: 'TraceModalExport',
  components: {
    vSelect,
    SpecificationFormSelect,
  },
  data() {
    return {
      from_spec: null,
      to_spec: null,
      file: '',
      loading_status: false,
      document_layout: 'table',
      show_deleted: false,
      all_columns: [],
      show_text: false,
      additional_columns_top: [],
      additional_columns_low: [],
      trace_direction: 'coverage',
      trace_types: ['trace'],
      trace_options: [
        {
          text: 'Coverage (of Higher Level)',
          value: 'coverage',
        },
        {
          text: 'Trace (to Lower Level)',
          value: 'trace',
        },
      ],
      trace_type_options: [
        { value: 'trace', text: 'Trace' },
        { value: 'dependency', text: 'Dependency' },
        { value: 'review', text: 'Reviewer' },
        { value: 'blocks', text: 'Blocks' },
      ],
    }
  },
  methods: {
    onShown() {
      const { columns, specification, show_deleted } = this.$store.state.requirementsTableLegacy
      this.additional_columns_top = []
      this.additional_columns_low = []
      this.trace_types = ['trace']
      this.from_spec = specification || this.from_spec
      // eslint-disable-next-line camelcase
      this.show_deleted = show_deleted || this.show_deleted

      const params = { model: this.$store.state.model.id }
      axiosIns.get('/api/v2/requirements/attributes', { params }).then(({ data }) => {
        const { attrs } = data
        const nonVisibleCols = ['old_id', 'parent_rel', 'trace', 'coverage', 'traceability']
        this.all_columns = [...attrs.filter(attr => !nonVisibleCols.includes(attr)), 'issues', 'tests']
      })
    },
    changeSpecificationFrom(id) {
      // Get the ID when you change in the dropdown
      this.from_spec = id
    },
    changeSpecificationTo(id) {
      // Get the ID when you change in the dropdown
      this.to_spec = id
    },
    exportRequirements(evt) {
      const modelId = this.$store.state.model.id
      // This prevents the modal from closing prematurely
      evt.preventDefault()
      this.$store.dispatch('specifications/selectSpecification', this.from_spec)

      // Show loading circle and button to users
      this.loading_status = true

      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      // Create and append data to the json file
      const filename = `requirements_trace_${now}.xlsx`

      const params = {
        model: modelId,
      }
      axiosIns.post('/api/v2/requirements/trace_report', {
        model: modelId,
        from_spec: this.from_spec,
        to_spec: this.to_spec,
        columns_to: this.additional_columns_top,
        columns_from: this.additional_columns_low,
        show_text: this.show_text,
        trace_types: this.trace_types,
      }, { params, responseType: 'blob' }).then(({ data }) => {
        FileSaver.saveAs(data, filename)
        this.loading_status = false
        this.$bvModal.hide('trace-export-modal')
      })
        .catch(() => {
          this.loading_status = false
          console.error('ERROR IN TRACE EXPORT')
        })
    },
    cancel() {
      this.loading_status = false
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables/_variables.scss';
.v-select-style-overrides {
  .vs__dropdown-toggle, .vs__dropdown-menu {
    text-transform: capitalize;
  }
}

.dark-layout {
  .vs__dropdown-menu {
    background: yellow;
    li {
      color: red;
    }
  }

  .v-select-style-overrides {
    .vs__dropdown-option--selected {
      background: lighten($info, 0.5%);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
