var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"trace-export-modal","title":"Export Requirements Trace to file","size":"lg","no-close-on-backdrop":"","no-close-on-esc":""},on:{"ok":_vm.exportRequirements,"shown":_vm.onShown},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Dismiss ")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading_status},on:{"click":function($event){return ok()}}},[(_vm.loading_status)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Exporting... ")],1):_c('span',[_vm._v(" Export Trace ")])])]}}])},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-25",staticStyle:{"opacity":"0.8"}},[_vm._v(" Select the Top Level Specification of the Trace ")]),_c('b-form-group',[_c('SpecificationFormSelect',{attrs:{"value":_vm.to_spec,"show-all":""},on:{"input":_vm.changeSpecificationTo}})],1)],1),_c('b-col',[_c('div',{staticClass:"mb-25",staticStyle:{"opacity":"0.8"}},[_vm._v(" Select the Lowest Level Specification of the Trace ")]),_c('b-form-group',[_c('SpecificationFormSelect',{attrs:{"value":_vm.from_spec,"show-all":""},on:{"input":_vm.changeSpecificationFrom}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"mb-25",staticStyle:{"opacity":"0.8"}},[_vm._v(" Additional Columns - Top Level ")]),_c('v-select',{staticClass:"v-select-style-overrides",attrs:{"multiple":"","label":"title","options":_vm.all_columns},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_c('span',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(title)+" ")])]}}]),model:{value:(_vm.additional_columns_top),callback:function ($$v) {_vm.additional_columns_top=$$v},expression:"additional_columns_top"}})],1),_c('b-col',[_c('div',{staticClass:"mb-25",staticStyle:{"opacity":"0.8"}},[_vm._v(" Additional Columns - Lower Level ")]),_c('v-select',{staticClass:"v-select-style-overrides",attrs:{"multiple":"","label":"title","options":_vm.all_columns},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var title = ref.title;
return [_c('span',{staticClass:"text-dark"},[_vm._v(" "+_vm._s(title)+" ")])]}}]),model:{value:(_vm.additional_columns_low),callback:function ($$v) {_vm.additional_columns_low=$$v},expression:"additional_columns_low"}})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"custom-control-info",model:{value:(_vm.show_text),callback:function ($$v) {_vm.show_text=$$v},expression:"show_text"}},[_vm._v(" Show text of Lower Level Requirements ")])],1),_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" Trace Types to Export: "),_c('b-form-select',{attrs:{"options":_vm.trace_type_options,"multiple":""},model:{value:(_vm.trace_types),callback:function ($$v) {_vm.trace_types=$$v},expression:"trace_types"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }