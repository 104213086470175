<template>
  <b-list-group-item button @click="routeToTest(testObject.id)">
    <h6>{{ testObject.ref_id }} - {{ testObject.name }}</h6>
    <b-badge v-if="testObject.latest_result && testObject.latest_result.toLowerCase() === 'passed'" class="mx-2" variant="success">
      Passed
    </b-badge>
    <b-badge v-else-if="testObject.latest_result && testObject.latest_result.toLowerCase() === 'failed'" class="mx-2" variant="danger">
      Failed
    </b-badge>
    <b-badge v-else class="mx-2 " variant="warning">
      Not Executed
    </b-badge>
    <br>
    <small>Click to view</small>
  </b-list-group-item>
</template>

<script>
export default {
  name: 'ListGroupItem',
  props: {
    testObject: {
      type: Object,
      default: null,
    },
  },
  computed: {
    test() {
      return this.renderTest(this.testObject)
    },
  },
  methods: {
    renderTest(detail) {
      console.debug(detail)
      // test = tests.find(x => x.id === test_id);
      const name = (detail.name).replace(/</g, '').replace(/>/g, '')

      const testWording = (detail.test).replace(/</g, '&lt;').replace(/>/g, '&gt;')
        .replace(/GIVEN/g, '<span style="color:#f7991c">GIVEN</span>')
        .replace(/WHEN/g, '<span style="color:#f7991c">WHEN</span>')
        .replace(/THEN/g, '<span style="color:#f7991c">THEN</span>')
        .replace(/AND/g, '<span style="color:#f7991c">AND</span>')
      const text = `<pre><code>${testWording}</code></pre>`

      return text
    },
    async routeToTest(id) {
      // TODO: temporary fix. store data was not filled at time of route change
      await this.$store.dispatch('tests/fetchTests', { model_id: this.$route.params.modelId })
      await this.$router.push(
          {
            name: 'model_test_focus',
            params: { testId: id },
          },
      )
    },

  },
}
</script>
