<template>
  <div>
    <h5>{{ title }}</h5>
    <b-form-input
      v-model="query"
      type="search"
      placeholder="Search..."
    />
    <div v-if="loading">
      <b-overlay show opacity="0.1">
        <template #overlay>
          <b-spinner small />
          {{ " " + loadingText }}
        </template>
        <b-card />
      </b-overlay>
    </div>
    <div v-else>
      <draggable
        :group="group"
        :value="items"
        tag="b-list-group"
        :style="{ overflowY: maxHeight ? 'scroll' : '', maxHeight: maxHeight}"
        @change="emitInput"
      >
        <b-list-group-item
          v-for="(item, index) in items"
          :key="`${title}-${index}`"
        >
          <slot name="item" :item="item" />
        </b-list-group-item>
      </draggable>
      <b-card v-if="!value.length">
        <b-card-text>
          <em>{{ noItemText }}</em>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'AssociatorListItemGroup',
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    idField: {
      type: String,
      default: 'id',
    },
    title: {
      type: String,
      default: '',
    },
    group: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    loadingText: {
      type: String,
      default: 'Loading...',
    },
    noItemText: {
      type: String,
      default: 'No Items',
    },
    maxHeight: {
      type: String,
      default: null,
    },
    objectArray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
    }
  },
  computed: {
    items() {
      // TODO add fillter to ensure no doubling up on array id field
      const localValue = this.value
      if (this.query !== '') {
        return localValue.filter(item => {
          let flag = false
          Object.keys(item).forEach(key => {
            if (String(item[key]).toLowerCase().includes(this.query.toLowerCase())) {
              flag = true
            }
          })
          return flag
        })
      }
      return localValue
    },
  },
  methods: {
    emitInput({ added = null, removed = null, moved = null }) {
      let localValue = this.value
      if (added) {
        localValue.splice(added.newIndex, 0, added.element)
      } else if (removed) {
        localValue.splice(localValue.indexOf(removed.element), 1)
      } else if (moved) {
        localValue = this.arrayMover(localValue, moved.oldIndex, moved.newIndex)
      }
      this.$emit('input', localValue)
    },
    // moves array items
    arrayMover(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
      return arr
    },

  },
}
</script>

<style scoped>

</style>
