<template>
  <b-modal
    id="add-interface-table-modal"
    title="Add Interface Table"
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    @ok="generateTable"
    @shown="onShown"
  >
    <div style="opacity: 0.8" class="mb-25">
      Select Interfaces to Include in Table
    </div>
    <b-row v-if="loaded">
      <b-col>
        <b-table-simple small hover responsive sticky-header="80vh">
          <b-thead>
            <b-tr>
              <b-th>
                <label class="form-checkbox">
                  <input v-model="selectAll" type="checkbox" @click="select">
                  <i class="form-icon" />
                </label>
              </b-th>
              <b-th>Identification</b-th>
              <b-th>Source</b-th>
              <b-th>Destination</b-th>
              <b-th>Resource Exchanged</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="iface in allInterfaces" :key="iface.interface_properties.id">
              <b-td>
                <label class="form-checkbox">
                  <input v-model="iface.selected" type="checkbox">
                  <i class="form-icon" />
                </label>
              </b-td>
              <b-td v-if="iface.interface_properties.display_id && iface.interface_properties.display_id !== ''">
                {{ iface.interface_properties.display_id }}
              </b-td>
              <b-td v-else>
                {{ iface.interface_properties.id }}
              </b-td>
              <b-td>{{ iface.source }}</b-td>
              <b-td>{{ iface.target }}</b-td>
              <b-td>{{ iface.resource }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <b-overlay v-else show opacity="0.3">
      <template v-slot:overlay>
        <h1 style="display: inline-block">
          Loading Interfaces
        </h1> <b-spinner />
      </template>
      <div style="height: 50vh;" />
    </b-overlay>

    <div style="opacity: 0.8" class="mb-25">
      Placement in relation to {{ requirement.display_id }}
    </div>
    <b-row>
      <b-col>
        <b-form-radio-group v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="direction" :aria-describedby="ariaDescribedby" name="some-radios" value="before">
            Before
          </b-form-radio>
          <b-form-radio v-model="direction" :aria-describedby="ariaDescribedby" name="some-radios" value="after">
            After
          </b-form-radio>
          <b-form-radio v-model="direction" :aria-describedby="ariaDescribedby" name="some-radios" value="child">
            Child
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Generating...
        </span>
        <span v-else>
          Add Interfaces Table
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SpecificationFormSelect from '@/components/Specifications/Forms/Select.vue'

export default {
  name: 'AddCoverageTableModal',
  props: {
    requirement: {
      type: Object,
      default: null,
    },
    spec: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      parentSpec: null,
      childSpec: null,
      loading_status: false,
      loaded: false,
      direction: 'after',
      allInterfaces: [],
      selected: [],
      selectAll: false,
    }
  },
  methods: {
    onShown() {
      this.loaded = false
      const params = {
        model: this.$store.state.model.id,
      }
      this.$http
        .get('/api/v2/interfaces/get_interfaces_simple', { params })
        .then(({ data }) => {
          console.log('interfaces: ', data)
          this.allInterfaces = data
          this.allInterfaces = this.allInterfaces.map(i => ({ selected: false, ...i }))
          this.selectAll = false
          this.loading_status = false
          this.loaded = true
        })
    },
    select() {
      if (this.selectAll) {
        this.allInterfaces.forEach(i => {
          i.selected = true
        })
      } else {
        this.allInterfaces.forEach(i => {
          i.selected = false
        })
      }
    },
    generateTable(evt) {
      // This prevents the modal from closing prematurely
      evt.preventDefault()
      this.loading_status = true
      const payload = {
        model_id: this.$store.state.model.id,
        spec_id: this.spec,
        interfaces: this.allInterfaces.filter(iface => iface.selected).map(c => (c.interface_properties.id)),
        target_node: this.requirement.id,
        relation: this.direction,

      }
      this.$http
        .post('/api/v2/specifications/generate_interface_table', payload)
        .then(({ data }) => {
          console.log('New Req: ', data)
          this.loading_status = false
          this.$bvModal.hide('add-interface-table-modal')
          this.$store.dispatch('requirementsTableLegacy/getData', { requirements: [data.id], setLayout: true }, { root: true })
          this.$store.dispatch('requirements/selectRequirement', data.id)
        })
    },
    setParentSpec(id) {
      this.parentSpec = id
    },
    setChildSpec(id) {
      this.childSpec = id
    },
    cancel() {
      this.loading_status = false
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables/_variables.scss';
.v-select-style-overrides {
  .vs__dropdown-toggle, .vs__dropdown-menu {
    text-transform: capitalize;
  }
}

.dark-layout {
  .vs__dropdown-menu {
    background: yellow;
    li {
      color: red;
    }
  }

  .v-select-style-overrides {
    .vs__dropdown-option--selected {
      background: lighten($info, 0.5%);
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
