<template>
  <app-collapse-item title="" class="p-0">
    <template slot="header">
      <div class="flex-column">
        <div class="d-flex align-middle">
          <b-badge class="mr-50" :variant="items.length > 0 ? 'info' : 'muted'">
            {{ items.length ? items.length : '-' }}
          </b-badge>
          <span class="font-weight-bold">{{ title }}</span>
        </div>
      </div>
      <!--<span class="font-weight-bolder">-->
      <!--  <b-badge class="mr-50" :variant="items.length > 0 ? 'info' : 'muted'">-->
      <!--    {{ // items.length ? items.length : '-' }}-->
        <!--</b-badge>-->
        <!--{{ // title }}-->
      <!--</span>-->
    </template>
    <div style="overflow-y: auto; max-height: 40vh">
      <list-notes v-if="items.length && type ==='notes'" :all-notes="items" />
      <b-list-group v-else-if="items.length && type ==='bt'" flush>
        <ListGroupItemBT
          v-for="(bt, index) in items"
          :key="`bt_${index}`"
          :bt="bt"
        />
      </b-list-group>
      <b-list-group v-else-if="items.length && type ==='entity'" flush>
        <ListGroupItemEntity
          v-for="(item, index) in items"
          :key="`entity_${index}`"
          :component="item"
        />
      </b-list-group>
      <b-list-group v-else-if="items.length && type ==='test'" flush>
        <ListGroupItemTest
          v-for="(item, index) in items"
          :key="`entity_${index}`"
          :test="item"
        />
      </b-list-group>
      <b-list-group v-else-if="items.length && (type === 'uncertainties' || type === 'issues')" flush>
        <ListGroupItemIssue
          v-for="(item, index) in items"
          :key="`uncertainty_${index}`"
          :issue="item"
        />
      </b-list-group>
      <b-list-group v-else-if="items.length && type ==='interfaces'" flush>
        <ListGroupItemInterface
          v-for="(item, index) in items"
          :key="`interface_${index}`"
          :an-interface="item"
        />
      </b-list-group>
      <b-list-group v-else-if="items.length && type ==='releases'" flush>
        <ListGroupItemRelease
          v-for="(item, index) in items"
          :key="`release_${index}`"
          :release="item"
        />
      </b-list-group>
      <b-list-group v-else-if="items.length" flush>
        <ListGroupItemGeneral
          v-for="(item, index) in items"
          :id="item.name"
          :key="`other_${index}`"
        />
      </b-list-group>
      <span v-else class="ml-50 text-muted">
        No linked {{ title }}
      </span>
      <b-button
        v-if="type === 'uncertainties' || type === 'issues'"
        variant="outline-success"
        class="mt-1 w-100"
        @click="$bvModal.show('link-issues-to-requirements-modal')"
      >
        Link Issues
      </b-button>
      <b-button
        v-if="type === 'test'"
        variant="outline-success"
        class="mt-1 w-100"
        @click="$bvModal.show('link-test-cases-to-requirements-modal')"
      >
        Link Test Cases
      </b-button>
      <b-button
        v-if="type === 'notes'"
        block
        class="mt-1"
        variant="outline-success"
        @click="$bvModal.show('create-note-modal')"
      >
        Create Note
      </b-button>
      <b-button
        v-if="type === 'entity'"
        block
        class="mt-1"
        variant="outline-success"
        @click="$bvModal.show('associate-entities-requirements-modal')"
      >
        Associate Entities
      </b-button>

    </div>
  </app-collapse-item>
</template>

<script>
import ListNotes from '@/components/Notes/ListNotes.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ListGroupItemGeneral from '@/components/Forms/ListGroupItems/ListGroupItemGeneral.vue'
import ListGroupItemBT from '@/components/Forms/ListGroupItems/ListGroupItemBT.vue'
import ListGroupItemEntity from '@/components/Forms/ListGroupItems/ListGroupItemEntity.vue'
import ListGroupItemTest from '@/components/Forms/ListGroupItems/ListGroupItemTest.vue'
import ListGroupItemIssue from '@/components/Forms/ListGroupItems/ListGroupItemIssue.vue'
import ListGroupItemInterface from '@/components/Forms/ListGroupItems/ListGroupItemInterface.vue'
import ListGroupItemRelease from '@/components/Forms/ListGroupItems/ListGroupItemRelease.vue'

export default {
  name: 'RequirementDetailsSidebarCollapse',
  components: {
    ListNotes,
    AppCollapseItem,
    ListGroupItemEntity,
    ListGroupItemBT,
    ListGroupItemGeneral,
    ListGroupItemTest,
    ListGroupItemIssue,
    ListGroupItemInterface,
    ListGroupItemRelease,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
