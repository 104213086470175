<template>
  <div>
    <h6 class="text-info">
      Notes
    </h6>

    <b-list-group v-if="allNotes && allNotes.length > 0" flush>

      <b-list-group-item
        v-for="(note, index) in allNotes" :key="index"
        class="list-item d-inline-flex w-100"
        :data-note-id="note.id"
      >
        <div class="mr-50">
          <b-avatar variant="info" size="sm">
            <!-- TODO Resolve User avatar -->
          </b-avatar>
        </div>

        <div class="w-100 flex-column">
          <div class="d-inline-flex w-100 justify-content-between">
            <p class="w-100 font-weight-bold font-small-3">
              <span class="text-nowrap">
                {{ getUserUsername(note.created_by) }}
                <span
                  class="ml-75 text-muted font-small-2 text-nowrap"
                  :title="$options.filters.formatDate(note.created)"
                >
                  {{ note.created | diffForHumansMaxTwoWeeks }}
                </span>
              </span>

              <span
                v-if="note.updated"
                class="ml-75 text-muted font-small-2 text-nowrap"
                :title="`${$options.filters.diffForHumansMaxTwoWeeks(note.updated)} by ${getUserUsername(note.updated_by)}`"
              >
                Edited {{ note.updated | diffForHumansMaxTwoWeeks }}
              </span>
            </p>
            <p class="font-small-3 text-nowrap">
              {{ note.type === 'note' ? 'Generic note' : 'How might we?' }}
            </p>
          </div>

          <span class="v-html-edit" v-sanitized-html="note.text" />

          <p v-if="!readOnly" class="mt-50">
            <b-link
              class="font-small-2 font-weight-bold mr-50 text-primary"
              @click="showUpdateNoteModal(note.id)"
            >
              Edit
            </b-link>
            <b-link
              class="font-small-2 font-weight-bold text-danger"
              @click="showDeleteNoteModal(note.id)"
            >
              Delete
            </b-link>
          </p>
        </div>
      </b-list-group-item>

    </b-list-group>

    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No linked notes
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListNotes',
  props: {
    allNotes: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    // Open the modals from the global modal functions
    const showUpdateNoteModal = noteId => { context.root.notesModalUpdate(noteId) }
    const showDeleteNoteModal = noteId => { context.root.notesModalDelete(noteId) }

    return {
      showUpdateNoteModal,
      showDeleteNoteModal,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.v-html-edit {
  p { margin: 0; }
}
</style>
