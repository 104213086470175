<template>
  <b-modal
    id="delete-requirement-modal"
    title="Delete Requirement"
    size="lg"
    ok-title="Delete the Requirement AND its children"
    ok-variant="danger"
    cancel-title="No, keep it"
    cancel-variant="outline-secondary"
    :ok-disabled="loading"
    :cancel-disabled="loading"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="onConfirm"
  >
    <p>
      Are you sure you wish to delete this Requirement
      <span class="text-danger font-weight-bolder">and all its child Requirements</span>?
    </p>

    <p>
      This will close any associated Issues that aren't linked to any other Requirements.
      All other associated entities will be unaffected.
    </p>

    <hr>
    <h4 v-if="loading">
      Deleting Requirement
      <b-spinner />
    </h4>
    <div v-else>
      <h4 class="text-warning font-weight-bolder">
        {{ requirement.display_id }}
      </h4>
      <p v-sanitized-html="requirement.object_text" />
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DeleteRequirement',
  props: {
    isFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
    }),
    requirement() {
      return this.selected_requirement.properties
    },
  },
  methods: {
    onConfirm() {
      this.loading = true
      this.$store
        .dispatch('requirements/deleteRequirement', this.requirement.id)
        .then(data => {
          this.$store.dispatch('requirements/selectRequirement', this.requirement.id)
          const reqCount = data.deletedRequirements.length
          let toastText = `Deleted ${reqCount} Requirements`
          if (reqCount === 1) {
            toastText = 'Deleted 1 Requirement'
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Requirement and children',
              text: toastText,
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading = false
          if (this.isFocus) {
            this.$router.push({
              name: 'legacy_requirements_table',
            })
          }
          this.selected_requirement.properties.deleted = true
          this.$bvModal.hide('delete-requirement-modal')
        })
    },
  },
}
</script>
