<template>
  <b-modal
    id="associate-tests-requirements-modal"
    title="Associate Tests"
    size="xl"
    @show="allocated_tests = selected_requirement.tests"
  >
    <associator
      v-model="allocated_tests"
      :right-title="`Tests Associated with ${requirement.properties.id}`"
      left-title="Tests:"
      :items="tests"
      id-field="id"
      max-height="60vh"
      group="associate-tests-requirements"
    >
      <template v-slot:item="{ item }">
        <tests-forms-list-group-item :test-object="item" />
      </template>
    </associator>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="primary"
        @click="ok()"
      >
        Allocate Tests
      </b-button>
      <b-button @click="cancel()">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Associator from '@/components/Forms/Associator.vue'
import TestsFormsListGroupItem from '@/components/Tests/Forms/ListGroupItem.vue'

import { mapState } from 'vuex'

export default {
  name: 'AllocateTests',
  components: {
    Associator,
    TestsFormsListGroupItem,
  },
  data() {
    return {
      allocated_tests: [],
    }
  },
  computed: {
    ...mapState({
      tests: state => state.tests.tests,
      selected_requirement: state => state.requirements.selected_requirement,
    }),
    requirement() { return this.selected_requirement },
  },
}
</script>

<style scoped>

</style>
