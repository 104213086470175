<template>
  <b-form>
    <b-row>
      <b-col cols="6">
        <associator-list-item-group
          :value="items.filter(item => !value.map(i => i[idField]).includes(item[idField]))"
          :title="leftTitle"
          :group="group"
          :loading="leftLoading"
          :loading-text="leftLoadingText"
          :max-height="maxHeight"
        >
          <template v-slot:item="{ item }">
            <slot name="item" :item="item" />
          </template>
        </associator-list-item-group>
      </b-col>
      <b-col cols="6">
        <associator-list-item-group
          :value="value"
          :title="rightTitle"
          :group="group"
          :loading="rightLoading"
          :loading-text="rightLoadingText"
          :max-height="maxHeight"
          @input="val => $emit('input', val)"
        >
          <template v-slot:item="{ item }">
            <slot name="item" :item="item" />
          </template>
        </associator-list-item-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import AssociatorListItemGroup from '@/components/Forms/AssociatorListGroup.vue'

export default {
  name: 'Associator',
  components: {
    AssociatorListItemGroup,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    idField: {
      type: String,
      default: 'id',
    },
    rightTitle: {
      type: String,
      default: '',
    },
    leftTitle: {
      type: String,
      default: '',
    },
    group: {
      type: String,
      required: true,
    },
    leftLoading: {
      type: Boolean,
      default: false,
    },
    leftLoadingText: {
      type: String,
      default: 'Loading...',
    },
    rightLoading: {
      type: Boolean,
      default: false,
    },
    rightLoadingText: {
      type: String,
      default: 'Loading...',
    },
    maxHeight: {
      type: String,
      default: null,
    },
  },
}
</script>
