<template>
  <b-list-group-item
    class="entity"
    button
  >
    <!--@click="openRequirement()"-->
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex flex-column">
        <div class="d-flex d-inline-flex flex-nowrap">
          <b-badge pill variant="light-primary">
            <span class="font-weight-bold mr-25">Phase:</span>
            <span class="font-weight-bolder">{{ oqe.phase }}</span>
          </b-badge>
          <b-badge pill variant="light-primary" class="mx-50">
            <span class="font-weight-bold mr-25">Method:</span>
            <span class="font-weight-bolder">{{ oqe.method }}</span>
          </b-badge>
          <b-badge pill :variant="resultPillVariant()">
            <span class="font-weight-bold mr-25">Result:</span>
            <span class="font-weight-bolder">{{ oqe.record_result }}</span>
          </b-badge>
        </div>
        <hr class="my-25">
        <p v-sanitized-html="oqe.statement" />
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    oqe: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resultPillVariant() {
      if (this.oqe.record_result === 'Pass') { return 'light-success' }
      if (this.oqe.record_result === 'Fail') { return 'light-danger' }
      if (this.oqe.record_result === 'Not Determined') { return 'light-secondary' }
      return 'light-info'
    },
    // openRequirement() {
    //   this.$bvModal
    //     .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
    //       title: 'Open Requirement',
    //       size: 'sm',
    //       okVariant: 'success',
    //       okTitle: 'Open Requirement',
    //       cancelTitle: 'Dismiss',
    //       cancelVariant: 'outline-danger',
    //       hideHeaderClose: false,
    //       centered: true,
    //     })
    //     .then(value => {
    //       if (value) {
    //         this.$router.push(
    //           {
    //             name: 'legacy_requirements_table',
    //             query: { focus: this.req.id },
    //           },
    //         )
    //       }
    //     })
    // },
  },
}
</script>

<style lang="scss">
.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
