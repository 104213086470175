<template>
  <div>
    <b-list-group v-if="entityArray.length > 0 && entityArray[0] && entityArray[0].id !== null">
      <ListGroupItemQualificationRecord
        v-for="(item, index) in entityArray"
        :key="index"
        :oqe="item"
      />
    </b-list-group>

    <div v-else>
      <span class="ml-2 font-small-3 text-muted">No {{ label }}</span>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import ListGroupItemQualificationRecord from '@/components/Forms/ListGroupItems/ListGroupItemQualificationRecord.vue'

export default {
  name: 'ListGroupQualificationRecords',
  components: {
    ListGroupItemQualificationRecord,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    entityArray: {
      type: Array,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
    showHeading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>
