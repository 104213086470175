<template>
  <vue-context v-if="!selectedRequirement.deleted" ref="menu">
    <li>
      <b-link @click="openRequirement">
        <span class="text-primary font-weight-bolder">Open</span>
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('edit-requirement-modal')">
        Edit
      </b-link>
    </li>
    <li class="v-context__sub">
      <b-link>
        Create
      </b-link>
      <ul class="v-context">
        <li>
          <b-link @click="createRequirementWithTarget('before')">
            Requirement Before
          </b-link>
        </li>
        <li>
          <b-link @click="createRequirementWithTarget('after')">
            Requirement After
          </b-link>
        </li>
        <li>
          <b-link @click="createRequirementWithTarget('child')">
            Requirement as Child
          </b-link>
        </li>
      </ul>
    </li>
    <li class="v-context__sub">
      <b-link>
        Generate
      </b-link>
      <ul class="v-context">
        <li>
          <b-link @click="openModal('add-coverage-table-modal')">
            Coverage Report
          </b-link>
        </li>
        <li>
          <b-link @click="openModal('add-interface-table-modal')">
            Interfaces Table
          </b-link>
        </li>
      </ul>
    </li>
    <li>
      <b-link @click="openModal('move-requirement-modal')">
        Move
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('copy-requirement-modal')">
        Copy
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('parse-requirement-modal2')">
        Parse
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('decompose-functions-modal')">
        Decompose
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('forward-trace-requirement-modal')">
        <feather-icon icon="ArrowRightIcon" />
        Trace Forward
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('backward-trace-requirement-modal')">
        <feather-icon icon="ArrowLeftIcon" />
        Trace Backward
      </b-link>
    </li>
    <!--    <li>-->
    <!--      <b-link @click="openModal('relate-requirement-modal')">-->
    <!--        Other Relationships-->
    <!--      </b-link>-->
    <!--    </li>-->
    <li>
      <b-link @click="openHistory('history-requirement-modal')">
        History
      </b-link>
    </li>
    <li>
      <b-link @click="openModal('delete-requirement-modal')">
        <span class="text-danger">Delete</span>
      </b-link>
    </li>
  </vue-context>
  <vue-context v-else ref="menu">
    <li>
      <b-link @click="openHistory('history-requirement-modal')">
        History
      </b-link>
    </li>
    <li>
      <b-link @click="restoreRequirement">
        <span class="text-success">Restore</span>
      </b-link>
    </li>
  </vue-context>
</template>

<script>
import VueContext from 'vue-context'
import { mapState } from 'vuex'

export default {
  name: 'RequirementTableContextMenu',
  components: {
    VueContext,
  },
  computed: {
    ...mapState({
      selectedRequirement: state => state.requirements.selected_requirement.properties,
      selectedNode: state => state.requirements.selected_requirement.id,
    }),
  },
  methods: {
    openRequirement() {
      this.$router.push({
        name: 'requirement_detail',
        params: { requirementId: this.selectedRequirement.id },
      })
    },
    restoreRequirement() {
      this.$store.dispatch('requirements/restoreRequirement', this.selectedRequirement.id)
      this.selectedRequirement.deleted = false
    },
    openModal(id) {
      this.$bvModal.show(id)
    },
    openHistory() {
      this.$router.push(
        {
          name: 'node_history',
          params: {
            modelId: sessionStorage.getItem('kompozition-workspace'),
            nodeId: this.selectedRequirement.id,
          },
        },
      )
    },
    createRequirementWithTarget(relation) {
      const targetPosition = {
        target_node: this.selectedRequirement.id,
        relation,
      }
      this.$store.commit('requirements/SET_TARGET_POSITION', targetPosition)
      this.openModal('add-requirement-modal')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context';
</style>
