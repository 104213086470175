<template>
  <b-modal
    id="add-requirement-modal"
    title="Create Requirement"
    size="xl"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    @shown="$refs.add_fields.modalOpened()"
  >
    <requirement-form-fields
      ref="add_fields"
      :requirement="new_requirement"
      mode="add"
      @add-with-parse="createAndParseRequirement"
      @add-without="createRequirement"
      :new-default="specificationId"
      :initial-section-relation="initialRelation"
    />
    <!--<template v-slot:modal-footer="{ }">-->
    <!--<LabelWithHoverIcon-->
    <!--  popover-text="Either add your Requirement and parse which does:-->
    <!--  <br>-->
    <!--  xyz-->
    <!--  <br>-->
    <!--  Or you can add omit this and submit your Requirement without parsing."-->
    <!--  custom-size="30"-->
    <!--  popover-direction="left"-->
    <!--/>-->
    <!--  <b-button variant="primary" @click="createAndParseRequirement">-->
    <!--    Add Requirement-->
    <!--  </b-button>-->
    <!--  <b-button variant="outline-primary" @click="createRequirement">-->
    <!--    Add Requirement Without Parsing-->
    <!--  </b-button>-->
    <!--  <b-button @click="cancel()">-->
    <!--    Cancel-->
    <!--  </b-button>-->
    <!--</template>-->
  </b-modal>
</template>

<script>
import RequirementFormFields from '@/views/RequirementsTableLegacy/forms/Fields.vue'
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AddRequirement',
  components: {
    RequirementFormFields,
    // LabelWithHoverIcon,
  },
  props: {
    initialRelation: {
      type: Object,
      default: null,
    },
    specificationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      new_requirement: {
        id: '',
        section: '',
      },
    }
  },
  computed: {
    ...mapState({
      selected_requirement: state => state.requirements.selected_requirement,
      selectedSpecification: state => state.requirementsTableLegacy.specification,
    }),
  },
  methods: {
    ...mapActions({
      vuexCreateRequirement: 'requirements/createRequirement',
      vuexSelectRequirement: 'requirements/selectRequirement',
    }),
    createRequirement() {
      const payload = this.$refs.add_fields.getFields()
      payload.spec_id = this.specificationId ? this.specificationId : this.selectedSpecification
      return this.vuexCreateRequirement(payload)
        .then(({ id }) => {
          this.vuexSelectRequirement(id)
            .then(() => {
              console.debug('Created requirement: ', this.selected_requirement)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Requirement created',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('added')
              this.$bvModal.hide('add-requirement-modal')
            })
        })
        .catch(error => {
          console.error(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating Requirement',
              icon: 'AlertTriangleIcon',
              text: `${error}`,
              variant: 'danger',
            },
          })
          this.$bvModal.hide('add-requirement-modal')
        })
    },
    createAndParseRequirement() {
      const payload = this.$refs.add_fields.getFields()
      payload.spec_id = this.specificationId ? this.specificationId : this.selectedSpecification
      console.log(payload)
      return this.vuexCreateRequirement(payload)
        .then(({ id }) => {
          this.vuexSelectRequirement(id)
            .then(() => {
              console.debug('Created and Parsed Requirement: ', this.selected_requirement)
              this.$emit('added')
              this.$bvModal.hide('add-requirement-modal')
              setTimeout(() => {
                this.$bvModal.show('parse-requirement-modal2')
              }, 500)
            })
        })
        .catch(error => {
          console.error(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating and parsing the Requirement',
              icon: 'AlertTriangleIcon',
              text: `${error}`,
              variant: 'danger',
            },
          })
          this.$bvModal.hide('add-requirement-modal')
        })
    },
  },
}
</script>

<style scoped>
.modal-dialog {
    max-width: 600px !important;
}
</style>
